.settings {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__tabs {
        width: 20%;
        min-width: 260px;
    }

    &__tabs-list {
        border-radius: 4px;
        box-shadow: 0 8px 30px 5px rgba(0, 69, 104, 0.1);
    }

    &__tabs-btn {
        position: relative;
        display: block;
        width: 100%;
        padding: 20px;
        font-size: 17px;
        font-weight: $font--medium;
        line-height: 1.4;
        text-align: left;
        color: $color-grayish-blue;
        border-left: 2px solid transparent;
        transition: border-left 0.2s ease-out;

        &::before {
            position: absolute;
            content: '';
            background-color: transparent;
            pointer-events: none;
            transition-property: box-shadow, background, -webkit-box-shadow;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
        }

        &:hover {
            color: #19a299;
            border-left-color: #19a299;
        }

        &:hover::before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-shadow: 0 10px 50px 0 rgba(0, 69, 104, 0.2);
        }

        &.is-active {
            background-color: #e6e6e6;
            color: $color-light-green-sea;
            border-left-color: #19a299;

            &::before {
                content: none;
            }
        }
    }

    &__sections {
        width: calc(80% - 80px);
        padding: 25px;
        margin-left: 80px;
        border: 2px solid rgba(0, 69, 104, 0.1);
        background-color: $color-white;
        border-radius: 4px;
        box-shadow: 0 8px 30px 5px rgba(0, 69, 104, 0.1);
    }

    &__section-inner {
        margin-top: 23px;
    }

    &__section-text {
        font-size: 15px;
        line-height: 1.8;
    }

    &__section-text + &__section-text {
        margin-top: 5px;
    }

    &__section-part {
        margin-top: 30px;
    }

    &__section-part-title {
        margin-bottom: 15px;
    }

    &__section-steps-wrap {
        margin-top: 20px;
    }
}
