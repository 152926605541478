// Font families
// @type List
$font-stack-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;

// Font-sizes
// @type Length
$font--page: 14px !default;

// Font weights
// @type Length
$font--ultra-light: 100 !default;
$font--light: 200 !default; // or thin
$font--book: 300 !default; // or demi
$font--normal: 400 !default; // or regular
$font--medium: 500 !default;
$font--semi-bold: 600 !default; // or demi-bold
$font--bold: 700 !default;
$font--heavy: 800 !default; // or black/extra-bold
$font--fat: 900 !default; // or extra-black/poster/ultra-black

// Default line-height
// @type Length
$lh--base: 1.42857143; // 20/14

// Base grid width
// @type Length
$width--min: 768px !default;
$width--max: 1210px !default;

$page-header-height: 50px;
$page-spacer: 20px;

// Colors
// @type Color
$color-light-green-sea: #19a299;
$color-green-pine-craiola: #1d8a83;
$color-shamrock-craiola: #3fd38b;
$color-shamrock-craiola-light: #54d898;
$color-white: #fff;
$color-manatee: #8a98ac;
$color-ocean-blue: #263a5b;
$color-grayish-blue: #4c595f;
$color-santa-claus-hat: #e94d25;
$color-pear: #f0c740;
$color-saturated-purplish-pink: #f27273;
$color-lime-green: #3c3;
$color-gray-squirrel: #6c757d;
$color-smoky-white: #f7f7f7;
$color-cornflower-blue: #023f88;
$color-green-blue-craiola: #0b56af;

// Transitions
// @type Property Values
$transition--default: all 0.2s ease-out;

// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN
// $base-url: "http://cdn.example.com/assets/";
$base-url: './' !default;

// Breakpoints
$lg: 992px;
