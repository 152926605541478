.page {
    background-color: #f1f1f1;
    font-family: $font-stack-base;
    font-size: $font--page;
    font-weight: $font--normal;
    height: 100%;
    line-height: $lh--base;
    margin: 0;
    min-width: $width--min;
    padding: 0;

    // Remove dotted outline on input or button text in Firefox
    & *::-moz-focus-inner {
        border: none;
    }

    // Remove clear cross and reveal eye on input in IE11
    & *::-ms-clear,
    & *::-ms-reveal {
        display: none;
    }

    & *:focus {
        outline: none;
    }

    &__main {
        min-height: calc(100vh - #{$page-header-height});
    }

    &__app-root {
        position: relative;
    }

    &__screen-title {
        margin-bottom: 20px;
    }

    &__content {
        position: relative;
        padding-top: $page-spacer;
        padding-bottom: $page-spacer;
    }

    &__app-error {
        height: calc(100vh - #{$page-header-height} - (#{$page-spacer} * 2));
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // Visually hiding Google reCaptcha badge
    .grecaptcha-badge {
        visibility: hidden;
        opacity: 0;
        position: absolute !important;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px !important;
        width: 1px !important;
        margin-left: -1px;
        padding: 0;
        border: 0;
        transition: none !important;
        bottom: 0 !important;
        right: 0 !important;
        box-shadow: none !important;
    }
}
