$chat-header-height: 68px;

.chat {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__user-avatar {
        margin-right: 10px;
    }

    &__header {
        padding: 13px 15px;
        background-color: $color-white;
        height: $chat-header-height;
        flex: none;
    }

    &__header-inner {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__header-user {
        flex: 0 0 50%;
        max-width: 50%;
    }

    &__header-user-inner {
        display: flex;
        align-items: center;
    }

    &__header-user-info {
        word-break: break-word;
        flex: 1;
    }

    &__header-user-name {
        font-size: 15px;
        line-height: 1.2;
        font-weight: 600;
        margin: 0 0 5px;
        color: $color-ocean-blue;
    }

    &__header-user-message {
        color: $color-manatee;
        font-size: 14px;
        margin: 0;
    }

    &__header-actions {
        margin-left: auto;
    }

    &__body {
        margin-top: 15px;
        margin-bottom: 15px;
        overflow: auto;
        flex-grow: 1;
        flex-basis: 0; // for Safari to respect flexbox height
        position: relative;
    }

    &__message-list {
        list-style: none;
        margin: 0;
        padding: 0 30px;
    }

    &__message-list-item {
        margin-bottom: 15px;
    }

    &__body-preloader {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__body-error {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }

    &__body-error-text {
        margin-bottom: 10px;
        color: $color-santa-claus-hat;
        text-align: center;
        max-width: 275px;
    }

    &__body-refresh {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }

    &__body-refresh-icon {
        margin-right: 5px;
        fill: currentColor;
    }

    &__body-no-data {
        padding: 15px;
        font-size: 16px;
        color: $color-grayish-blue;
        text-align: center;
    }

    &__scroll-down-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: calc(100% - 60px);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: rgba(129, 167, 205, 0.2);
        fill: $color-manatee;
        transition: background-color 0.2s ease-in;

        &:hover {
            background-color: rgba(129, 167, 205, 0.4);
        }
    }

    &__scroll-down-count-message {
        position: absolute;
        top: -5px;
        right: -5px;
        height: 19px;
        min-width: 19px;
        padding: 2px 7px;
        font-size: 11px;
        font-weight: $font--medium;
        line-height: 1.4;
        border-radius: 12px;
        background-color: $color-green-pine-craiola;
        color: $color-white;
        white-space: nowrap;
    }

    &__day {
        margin-bottom: 16px;
        text-align: center;
    }

    &__day-badge {
        display: inline-block;
        background-color: rgba(129, 167, 205, 0.1);
        color: #8294ca;
        padding: 3px 4px;
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 4px;
    }

    &__footer {
        background-color: $color-white;
        padding: 15px 15px 16px;
        flex: none;
    }

    &__no-active-chat {
        display: flex;
        background-color: $color-white;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &__no-active-chat-text {
        font-size: 16px;
        color: $color-gray-squirrel;
    }
}
