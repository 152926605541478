.os-theme-dark.os-theme-dark--custom > .os-scrollbar-horizontal {
    height: 6px;
    padding-bottom: 0;
}
.os-theme-dark.os-theme-dark--custom .os-scrollbar-vertical {
    width: 6px;
    padding-right: 0;
}
.os-theme-dark.os-theme-dark--custom .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: #19a299;
}
