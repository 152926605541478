.chat-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        position: relative;
    }

    &__preloader {
        padding: 10px;
        text-align: center;
    }

    &__error {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }

    &__error-text {
        margin-bottom: 10px;
        color: $color-santa-claus-hat;
        text-align: center;
        max-width: 275px;
    }

    &__refresh {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }

    &__refresh-icon {
        margin-right: 5px;
        fill: currentColor;
    }

    &__no-data {
        padding: 15px;
        color: $color-grayish-blue;
        text-align: center;
    }
}
