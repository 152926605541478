.auth-form {
    &__inner {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;
    }

    &__row {
        position: relative;

        & + & {
            margin-top: 25px;
        }

        p {
            color: $color-manatee;
        }
    }

    &__btn {
        border-radius: 3px;
        width: 400px;
        height: 41px;
        position: relative;
        font-size: 18px;
        line-height: 1;

        & + & {
            margin-top: 15px;
        }
    }

    &__grecaptcha-note {
        margin-top: 20px;
        color: $color-manatee;
    }

    &__grecaptcha-note-link,
    &__link {
        color: $color-light-green-sea;

        &:hover,
        &:focus {
            color: #168c84;
        }
    }

    &__footer {
        margin-top: 40px;
    }

    &__error {
        position: absolute;
        bottom: -18px;
        left: 10px;
    }

    &__reset {
        margin-top: 7px;
        text-align: center;
    }
}
