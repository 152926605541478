.title {
    color: $color-grayish-blue;
    font-family: inherit;
    font-weight: $font--medium;
    margin: 0;

    &--h1 {
        font-size: 35px;
        font-weight: $font--normal;
        line-height: 1.2;
    }

    &--h2 {
        font-size: 28px;
        line-height: 1.25;
    }

    &--h3 {
        font-size: 23px;
        line-height: 1.2;
    }

    &--h4 {
        font-size: 16px;
        line-height: 1.5;
    }

    &--h5 {
        font-size: 10px;
        line-height: 1.4;
    }
}
