.custom-scrollbar {
    -ms-overflow-style: none;

    &__track-vertical {
        top: 3px;
        bottom: 3px;
        right: 2px;
        width: 5px !important;
        background-color: transparent;
    }

    &__thumb-vertical {
        width: 100%;
        border-radius: 3px;
        transition: opacity 0.2s ease-out;
        background-color: $color-light-green-sea;

        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }

    &__view {
        .ie & {
            width: 100%;
            height: 100%;
        }
    }
}
