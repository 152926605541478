.solid-btn {
    width: 100%;
    font-size: 15px;
    line-height: 1.3;
    padding: 10px 20px;
    border-radius: 5px;
    transition-property: color, background;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    white-space: nowrap;
    letter-spacing: 0;

    &--green {
        background-color: $color-shamrock-craiola-light;
        transition: background-color 0.2s ease-out;
        color: $color-white;
        fill: currentColor;

        &:focus,
        &:hover {
            background-color: $color-shamrock-craiola;
        }

        &[disabled] {
            background-color: #d0d0d0;
            color: $color-white;
        }
    }

    &--blue {
        background-color: $color-green-blue-craiola;
        transition: background-color 0.2s ease-out;
        color: $color-white;
        fill: currentColor;

        &:focus,
        &:hover {
            background-color: $color-cornflower-blue;
        }

        &[disabled] {
            background-color: #d0d0d0;
            color: $color-white;
        }
    }
}
