$chat-sidebar-menu-height: 59px;
$chat-sidebar-search-height: 68px;

.chat-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__search {
        flex: none;
        height: $chat-sidebar-search-height;
        padding: 15px;
        margin-bottom: 20px;
        background-color: $color-white;
    }

    &__content {
        background-color: $color-white;
        flex-grow: 1;
        flex-basis: 0; // for Safari to respect flexbox height
        margin-bottom: $page-spacer;
    }

    &__menu {
        flex: none;
        height: $chat-sidebar-menu-height;
        padding: 0 15px;
        background-color: $color-white;

        @include above($lg) {
            width: 320px;
        }
    }

    &__menu-list {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__menu-item {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
    }

    &__menu-btn {
        height: 59px;
        display: block;
        width: 100%;
        padding: 19px 0 16px 0;
        color: $color-grayish-blue;
        fill: currentColor;
        border-bottom: 2px solid transparent;

        &:hover,
        &:focus {
            color: $color-light-green-sea;
        }

        &.is-active {
            color: $color-light-green-sea;
            border-bottom: 2px solid $color-light-green-sea;
        }
    }
}
