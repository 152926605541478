.create-operator-form {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-white;
    box-shadow: 0 8px 30px 5px rgba(0, 69, 104, 0.1);
    border-radius: 4px;

    &__inner {
        border: none;
        padding: 25px;
        margin: 0;
    }

    &__row {
        position: relative;
    }

    &__row + .create-operator-form__row {
        margin-top: 25px;
    }

    &__checkbox-prologue {
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: $font--medium;
        color: $color-manatee;

        &.has-error {
            color: $color-santa-claus-hat;
        }
    }

    &__checkbox-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -20px;
    }

    &__checkbox-item {
        width: 50%;
        margin-top: 20px;
    }

    &__footer {
        margin-top: 40px;
    }

    &__error {
        position: absolute;
        bottom: -21px;
        left: 10px;
    }
}
