.activation {
    &__preloader {
        padding: 15px;
        text-align: center;
    }

    &__preloader-title {
        display: block;
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
        color: $color-ocean-blue;
        margin-bottom: 15px;
    }

    &__error {
        padding: 15px;
        text-align: center;
        font-size: 16px;
        color: $color-santa-claus-hat;
    }
}
