.input {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: middle;

    &__label {
        font-size: 10px;
        position: absolute;
        left: 20px;
        opacity: 1;
        transform: translateY(3px);
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        color: $color-manatee;

        &--hidden {
            opacity: 0;
            transform: translateY(0);
        }
    }

    &__elem {
        display: block;
        background-color: rgba(129, 167, 205, 0.1);
        border: 1px solid transparent;
        border-radius: 4px;
        font-family: $font-stack-base;
        font-size: 22px;
        font-weight: $font--normal;
        height: 48px;
        line-height: 1;
        padding: 7px 20px 10px;
        text-align: left;
        transition: border 0.2s ease-out;
        width: 100%;
        color: $color-manatee;

        &:focus,
        &:hover {
            outline: 0;
            border-color: $color-shamrock-craiola;
        }

        &:focus {
            background-color: #f4f9ff;
        }

        @include placeholder() {
            font-size: 16px;
            font-weight: $font--light;
            color: #7d9abc;
        }

        .input--value & {
            padding-top: 15px;
            padding-bottom: 7px;
        }

        .input--error & {
            color: $color-santa-claus-hat;
            background-color: #f4f9ff;
            border-color: $color-santa-claus-hat;
        }

        .input--password & {
            padding-right: 40px;
        }
    }

    &__password-visibility-toggle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 13px;
        fill: $color-manatee;
    }
}
