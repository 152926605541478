.chats-screen {
    display: flex;
    height: calc(100vh - #{$page-header-height} - #{$page-spacer * 2});

    &__sidebar-panel {
        height: 100%;
        width: 320px;
        flex: none;
    }

    &__chat-panel {
        height: 100%;
        width: calc(100vw - 320px);
        margin-left: $page-spacer;
    }
}
