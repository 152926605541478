.auth-screen {
    &__inner {
        background-color: $color-white;
        border-radius: 3px;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;
        width: 460px;
    }

    &__header {
        display: flex;
        margin-bottom: 15px;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        color: $color-light-green-sea;
    }

    &__footer {
        padding-top: 25px;
        margin-top: 40px;
        border-top: 2px solid #f1f1f1;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    &__link {
        display: inline-block;
        font-size: 18px;
        font-weight: 400;
        color: $color-shamrock-craiola-light;
        text-decoration: none;
        transition: color 0.2s ease-out;

        &:active,
        &:focus,
        &:hover {
            color: $color-shamrock-craiola;
        }
    }
}
