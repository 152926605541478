.user {
    &__inner {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -14px;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $color-lime-green;
        }

        &--long {
            display: none;

            @include above($lg) {
                display: block;
            }
        }

        &--short {
            display: block;

            @include above($lg) {
                display: none;
            }

            padding: 5px;
            border-radius: 4px;
            background-color: #f4f4f4;
        }
    }

    &__self {
        font-size: 16px;
        font-weight: $font--semi-bold;
        letter-spacing: 0.13px;
        cursor: pointer;

        @include above($lg) {
            cursor: unset;
            color: $color-ocean-blue;
        }
    }
}
