.root {
    background-color: transparent;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-height: 100%;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
