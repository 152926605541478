.search {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;

    &__input {
        appearance: none;
        display: block;
        width: 100%;
        height: 38px;
        flex: 1 1 auto;
        margin: 0;
        padding: 6px 12px 6px 20px;
        border: none;
        border-radius: 3px 0 0 3px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-manatee;
        background-color: rgba(129, 167, 205, 0.1);
        background-clip: padding-box;

        @include placeholder {
            color: $color-gray-squirrel;
            opacity: 1;
        }
    }

    &__icon-wrap {
        display: flex;
        align-items: center;
        background-color: rgba(129, 167, 205, 0.1);
        color: $color-manatee;
        fill: currentColor;
        height: 38px;
        border-radius: 0 3px 3px 0;
        padding-right: 20px;
    }
}
