.app-error {
    color: $color-grayish-blue;

    &__columns {
        display: flex;
    }

    &__link-column {
        margin-right: 35px;
    }

    &__code {
        font-size: 160px;
        line-height: 1;
        margin: 0 0 20px;
    }

    &__icon {
        width: 134px;
        height: 134px;
        margin-top: 19px;
        margin-bottom: 28px;
        fill: currentColor;
    }

    &__message {
        font-size: 16px;
        margin: 0;
        padding-left: 10px;
        color: $color-santa-claus-hat;
    }

    &__return {
        font-size: 16px;
        color: $color-grayish-blue;
        line-height: 1;

        &:hover,
        &:focus {
            color: $color-light-green-sea;
        }
    }

    &__return-icon {
        fill: currentColor;
        margin-right: 9px;
    }
}
