.operators-screen {
    &__container {
        width: 92%;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        margin-bottom: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
