.unauthenticated-layout {
    padding-top: 60px;
    padding-bottom: 60px;

    &__logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        height: 46px;
        width: 140px;
        fill: $color-light-green-sea;
    }

    &__app-error {
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }
}
