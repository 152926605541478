.file-uploader {
    position: relative;
    overflow: hidden;

    &__input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;

        &::-webkit-file-upload-button {
            display: none; // necessary for rules cursor: pointer;
        }
    }

    &__label {
        display: block;
        color: $color-grayish-blue;
        fill: currentColor;

        .file-uploader__input:hover + &,
        .file-uploader__input:focus + & {
            color: $color-light-green-sea;
            cursor: pointer;
        }
    }
}
