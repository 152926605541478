.content-preloader {
    display: inline-flex;
    align-items: center;
    height: 11px;

    &__dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #19a299;
        animation: content-preloader 1.6s infinite ease-in-out both;

        .content-preloader--white & {
            background-color: $color-white;
        }

        .content-preloader--blue & {
            background-color: $color-grayish-blue;
        }

        .content-preloader--small & {
            width: 3px;
            height: 3px;
        }

        & + & {
            margin-left: 8px;
        }

        &:nth-child(1) {
            animation-delay: -0.64s;
        }

        &:nth-child(2) {
            animation-delay: -0.32s;
        }

        &:nth-child(3) {
            animation-delay: -0.16s;
        }
    }

    @keyframes content-preloader {
        0%,
        80%,
        100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1.5);
        }
    }
}
