.checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &__input {
        left: -9999px;
        position: absolute;
    }

    &__label {
        color: $color-manatee;
        padding-top: 3px;
        text-align: left;

        .checkbox--error & {
            color: $color-santa-claus-hat;
        }

        &:hover {
            color: $color-shamrock-craiola;

            .checkbox--error & {
                color: $color-santa-claus-hat;
            }
        }
    }

    &__input:checked + .checkbox__label,
    &__input:not(:checked) + .checkbox__label {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding-left: 27px;
        font-family: $font-stack-base;
        font-size: 15px;
        font-weight: $font--normal;
        line-height: 1.14285714;
        cursor: pointer;
    }

    &__input[disabled] + .checkbox__label {
        cursor: default;
    }

    &__input:focus + .checkbox__label {
        color: $color-shamrock-craiola;

        .checkbox--error & {
            color: $color-santa-claus-hat;
        }
    }

    &__input:checked + .checkbox__label::before,
    &__input:not(:checked) + .checkbox__label::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border: 1px solid transparent;
        background-color: rgba(129, 167, 205, 0.1);
        border-radius: 4px;
        transition: border 0.2s ease-out;

        .checkbox--error & {
            border-color: $color-santa-claus-hat;
        }
    }

    &__input:checked + .checkbox__label::after,
    &__input:not(:checked) + .checkbox__label::after {
        content: '';
        position: absolute;
        top: 7px;
        left: 6px;
        width: 10px;
        height: 6px;
        border-bottom: 2px solid $color-shamrock-craiola;
        border-left: 2px solid $color-shamrock-craiola;
        transition: transform 0.2s ease;
    }

    &__input:not(:checked) + .checkbox__label::after {
        opacity: 0;
        transform: rotate(-45deg) scale(0);
    }

    &__input:checked + .checkbox__label::after {
        opacity: 1;
        transform: rotate(-45deg) scale(1);
    }
}
