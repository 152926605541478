.instructions-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    counter-reset: list 0;

    &__item {
        position: relative;
        padding-left: 20px;

        &::before {
            content: counter(list) '. ';
            position: absolute;
            top: 0;
            left: 0;
            counter-increment: list;
            font-weight: $font--medium;
            font-size: 15px;
        }
    }

    &__item + &__item {
        margin-top: 20px;
    }

    &__heading {
        display: block;
        font-weight: $font--medium;
        font-size: 15px;
        margin-bottom: 10px;
    }

    &__description-container {
        display: block;
        margin-bottom: 15px;
        line-height: 1.8;
    }

    &__description {
        display: block;
    }

    &__description + &__description {
        margin-top: 6px;
    }

    &__code {
        display: block;
    }
}
