.crete-operator-screen {
    &__container {
        width: 92%;
        margin-left: auto;
        margin-right: auto;
    }

    &__header {
        margin-bottom: 30px;
    }

    &__title {
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
        font-weight: $font--medium;
        font-size: 14px;
        fill: currentColor;
        color: $color-ocean-blue;
        transition: color 0.2s ease-out;

        &:hover,
        &:focus {
            fill: $color-light-green-sea;
            color: $color-light-green-sea;
        }
    }

    &__link-icon {
        margin-right: 10px;
        transition: fill 0.2s ease-out;

        .crete-operator-screen__link:hover &,
        .crete-operator-screen__link:focus & {
            fill: $color-light-green-sea;
        }
    }
}
