.auth-error-modal {
    position: absolute;
    top: 0;
    left: 0;

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        overflow: auto;
        transform: translateZ(0);
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__content {
        position: absolute;
        border-radius: 4px;
        padding: 8px;
        padding-left: 15px;
        padding-right: 30px;
        width: 400px;
        background-color: $color-white;
    }

    &__message {
        font-size: 16px;
        color: $color-santa-claus-hat;
    }

    &__close-btn {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 12px;
        height: 12px;
        fill: $color-manatee;
    }

    &__close-icon {
        vertical-align: top;
    }
}
