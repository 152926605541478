.ghost-btn {
    padding: 10px 20px;
    border: 1px solid transparent;
    font-size: 15px;
    line-height: 1.3;
    border-radius: 5px;
    transition-property: color, border, background;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    white-space: nowrap;
    letter-spacing: 0;

    &--green {
        border-color: $color-shamrock-craiola-light;
        color: $color-shamrock-craiola-light;
        fill: currentColor;

        &:hover {
            border-color: $color-light-green-sea;
            color: $color-light-green-sea;
            fill: currentColor;
        }
    }
}
