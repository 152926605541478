.select {
    position: relative;
    width: 100%;
    height: 48px;

    &--sm {
        height: 25px;
    }

    &--lg {
        height: 54px;
    }

    &__control {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        border: 1px solid transparent;
        background-color: rgba(129, 167, 205, 0.1);
        border-radius: 6px;
        cursor: pointer;
        outline: 0;
        transition: $transition--default;

        .select--sm & {
            height: 25px;
        }
        .select--lg & {
            height: 54px;
        }

        .select--error & {
            color: $color-santa-claus-hat;
            background-color: #f4f9ff;
            border-color: $color-santa-claus-hat;
        }

        &:hover {
            outline: 0;
            border-color: $color-shamrock-craiola;

            .select--error & {
                border-color: $color-santa-claus-hat;
            }
        }

        &--is-disabled,
        &--is-disabled:hover {
            cursor: default;
            border-color: transparent;
        }
    }

    &__value-container {
        position: relative;
        display: flex;
        flex: 1 1 0;
        flex-wrap: wrap;
        align-items: center;
        padding: 6px 0 6px 20px;
        font-size: 22px;
        line-height: 1;
        overflow: hidden;
        font-weight: $font--normal;
        color: $color-manatee;
        text-align: left;

        .select--with-custom-label &{
            padding: 10px 0 2px 20px;
        }

        .select--sm & {
            padding: 2px 0 2px 7px;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.02em;
        }

        .select--lg & {
            font-weight: $font--medium;
            font-size: 18px;
        }

        .select--error & {
            color: $color-santa-claus-hat;
        }
    }

    &__single-value {
        position: absolute;
        max-width: calc(100% - 8px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .select:not(.select--with-custom-label) & {
            top: 49%;
            transform: translateY(-50%);
        }

        .select--sm & {
            max-width: calc(100% - 3px);
        }
    }

    &__indicators {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        align-self: stretch;
        padding: 2px 12px 0;
    }

    &__indicator-separator {
        display: none;
    }

    &__loading-indicator {
        color: rgb(204, 204, 204);
        display: flex;
        align-self: center;
        font-size: 4px;
        line-height: 1;
        margin-right: 4px;
        text-align: center;
        vertical-align: middle;
        padding: 8px;
        transition: color 150ms ease 0s;

        & > span {
            display: inline-block;
            height: 1em;
            vertical-align: top;
            width: 1em;
            background-color: currentColor;
            border-radius: 1em;
            animation: 1s ease-in-out 0ms infinite normal none running select-loading-indicator;

            &:nth-child(2) {
                animation-delay: 160ms;
            }

            &:last-child {
                animation-delay: 320ms;
            }

            & + & {
                margin-left: 1em;
            }
        }

        @keyframes select-loading-indicator {
            0%,
            80%,
            100% {
                opacity: 0;
            }
            40% {
                opacity: 1;
            }
        }
    }

    &__dropdown-indicator {
        position: relative;
        width: 10px;
        height: 5px;
        background-color: transparent;
        transition: $transition--default;
        color: $color-manatee;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-top: 5px solid currentColor;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;

            .select--error & {
                color: $color-santa-claus-hat;
            }

            .select--is-disabled & {
                content: none;
            }
        }

        & > svg {
            display: none;
        }

        .select__control--menu-is-open & {
            transform: rotate(-180deg);
        }
    }

    &__placeholder {
        position: absolute;
        top: 50%;
        font-size: 22px;
        line-height: 1;
        color: $color-manatee;
        transform: translateY(-50%);
        white-space: nowrap;
        font-weight: $font--normal;

        .select--sm & {
            font-weight: $font--light;
            font-size: 13px;
            line-height: 16px;
        }

        .select--lg & {
            font-weight: $font--light;
            font-size: 18px;
        }
    }

    &__menu {
        position: absolute;
        top: 100%;
        z-index: 1;
        width: 100%;
        margin: 5px 0;
        border: 1px solid #afc0d8;
        border-radius: 6px;
        background-color: #f2f6fA;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
        overflow: hidden;
    }

    &__menu-list {
        position: relative;
        max-height: 300px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .select--sm & {
            max-height: 95px;
        }
    }

    &__option,
    &__menu-notice {
        position: relative;
        display: block;
        width: 100%;
        padding: 8px 25px 6px 20px;
        border-bottom: 1px solid #afc0d8;
        cursor: default;
        user-select: none;
        font-size: inherit;
        color: $color-manatee;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        text-align: left;

        .select--sm & {
            padding: 5px 5px 4px 7px;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.02em;
        }

        .select--lg & {
            padding: 12px 25px 11px 11px;
        }

        &:last-child {
            border-bottom: none;
        }

        &--is-focused {
            background-color: #e0e5f5;
            outline: none;
        }

        &--is-selected {
            background-color: #e0e5f5;
        }
    }
}
