.send-message {
    &__inner {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__field {
        appearance: none;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 4px 10px 0 0;
        margin: 0;
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        background-color: $color-white;
        background-clip: padding-box;
        color: $color-grayish-blue;
        border: none;
        resize: none;
        overflow: hidden;
        height: auto;
        white-space: pre-wrap;

        @include placeholder {
            color: $color-gray-squirrel;
            opacity: 1;
        }

        &.hasScroll {
            overflow-y: scroll;
        }
    }

    &__actions {
        display: flex;
        padding-right: 10px;
    }

    &__btn-wrap {
        & + & {
            margin-left: 16px;
        }
    }

    &__btn {
        color: $color-grayish-blue;
        fill: currentColor;

        &:hover,
        &:focus {
            color: $color-light-green-sea;
        }
    }

    &__indicator:not(:empty) {
        padding-right: 16px;
    }

    &__error {
        fill: #d72828;
        display: block;
        width: 20px;
        height: 20px;
    }

    &__loader {
        box-sizing: border-box;
        border-radius: 50%;
        border-top: 2px solid #d0d0d0;
        border-right: 2px solid #d0d0d0;
        border-bottom: 2px solid #d0d0d0;
        border-left: 2px solid #19a299;
        transform: translateZ(0);
        animation: sender-load 1.1s infinite linear;
        width: 20px;
        height: 20px;
        display: block;
    }

    @keyframes sender-load {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &__file-preview-list {
        display: flex;
        flex-wrap: wrap;
        background-color: #f1f3f4;
        margin: 10px 0 5px;
        list-style: none;
    }

    &__file-preview-list-item {
        padding: 10px;
    }

    &__file-preview {
        background-color: $color-white;
        padding: 10px 15px;
        padding-right: 25px;
        border-radius: 3px;
        position: relative;
        overflow: hidden;
    }

    &__file-preview-name {
        color: $color-grayish-blue;
        display: block;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        max-width: 175px;
    }

    &__file-preview-extension,
    &__file-preview-size {
        font-size: 12px;
        color: $color-gray-squirrel;
    }

    &__file-preview-size {
        margin-left: 5px;
    }

    &__file-preview-close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f3f4;
        fill: $color-ocean-blue;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 3px;
        right: 3px;

        &:hover,
        &:focus {
            fill: $color-light-green-sea;
        }
    }

    &__file-preview-preloader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(240, 248, 255, 0.5);
    }

    &__file-preview-error {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color-santa-claus-hat, 0.5);
        fill: $color-white;
    }

    &__file-preview-error-retry {
        width: 28px;
        height: 27px;
        transform: rotate(0deg);
        transition: transform 0.5s ease-in;

        &:hover,
        &:focus {
            transform: rotate(360deg);
        }
    }

    &__btn-wrap {
        position: relative;
    }

    &__emoji-wrap {
        width: 355px;
        position: absolute;
        bottom: 46px;
        right: 0;
    }
}
